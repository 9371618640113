.button {
  appearance: none;
  background: var(--theme-secondary-normal);
  border: 2px solid var(--theme-secondary-dark);
  border-radius: 4px;
  color: var(--theme-text-dark);
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: all .1s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    background: var(--theme-secondary-dark);
    border-color: var(--theme-text-normal);
  }

  &:focus,
  &:active {
    transform: scale(.99);
    outline-color: var(--theme-text-normal);
  }
}