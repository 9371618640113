.base {
  background: var(--theme-secondary-normal);

  &__inner {
    margin: $size-spacing-small 0 0 $size-spacing-small;
    background: var(--theme-base);
    display: grid;
    grid-template-columns: $grid-mobile-template;
    grid-template-rows: min-content auto;
    grid-gap: $grid-mobile-gap;
    min-height: calc(100vh - 20px);
  }

  &--post {
    .base__inner {
      grid-template-rows: 60px 25vh 60px;
    }
  }

  &--tag {
    .base__inner {
      grid-template-rows: 60px 25vh 45px minmax(20px, max-content) auto;
    }
  }

  &--author {
    .base__inner {
      grid-template-rows: 60px 25vh 45px minmax(20px, max-content) auto;
    }
  }

  &__header {
    grid-column: 1 / -1;
    grid-row-start: 1;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
  }

  &.is-locked {
    height: 100vh;
    overflow: hidden;
  }

  &__footer {
    grid-column: 1 / -1;
  }
}

@media (min-width: 576px) {
  .base {
    &__inner {
      margin: $size-spacing-medium;
      grid-template-columns: $grid-desktop-template;
      grid-template-rows: min-content auto;
      grid-gap: $grid-desktop-gap;
      min-height: calc(100vh - 40px);
    }

    &--post {
      .base__inner {
        grid-template-rows: auto;
      }
    }

  }
}
