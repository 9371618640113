.contact-form {
  &__field {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__label {
    display: block;
  }
}

@media (min-width: 576px) {
  .contact-form {
    &__field {
      &--narrow {
        width: 75%;
      }
    }
  }
}