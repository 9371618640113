.author-bio {
  display: grid;
  grid-gap: $grid-mobile-gap;
  grid-template-columns: 1fr 4fr;
  
  &__image {
    display: block;
    width: 100%;
  }

  &__name {
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  &__link:not(:last-child) {
    margin-right: 20px;
  }

  &--boxed {
    padding: 20px;
    background: var(--theme-primary-normal);
    color: var(--theme-text-inverted);

    & .author-bio__name {
      color: var(--theme-text-inverted);
    }
  }
}

@media (min-width: 576px) { 
  .author-bio {
    grid-gap: $grid-desktop-gap;
  }
}
  