:where(html) {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.15;
}

:where(h1) {
  margin-top: .67em;
  margin-bottom: .67em;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin-top: 0;
  margin-bottom: 0;
}

:where(hr) {
  box-sizing: content-box;
  color: inherit;
  height: 0;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, pre, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(table) {
  text-indent: 0;
  border-color: currentColor;
}

:where(button, input, select) {
  margin: 0;
}

:where(button) {
  text-transform: none;
}

:where(button, input:is([type="button" i], [type="reset" i], [type="submit" i])) {
  -webkit-appearance: button;
}

:where(progress) {
  vertical-align: baseline;
}

:where(select) {
  text-transform: none;
}

:where(textarea) {
  margin: 0;
}

:where(input[type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(button, input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i]))::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:where(button, input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i]))::-moz-focusring {
  outline: 1px dotted ButtonText;
}

:where(:-moz-ui-invalid) {
  box-shadow: none;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  width: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border: solid;
  width: fit-content;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(summary) {
  display: list-item;
}

*, :after, :before {
  box-sizing: border-box;
}

:root {
  --theme-primary-light: #394c56;
  --theme-primary-normal: #2f3e46;
  --theme-primary-dark: #29363d;
  --theme-secondary-normal: #d2694b;
  --theme-secondary-dark: #c45131;
  --theme-tertiary: #52796f;
  --theme-text-heading: ;
  --theme-text-normal: #29363d;
  --theme-text-dark: #212b31;
  --theme-text-inverted: #fff;
  --theme-text-primary: #fff;
  --theme-text-secondary: #181f23;
  --theme-base: #f4f6f3;
  --theme-error: #942e2e;
}

@media (prefers-color-scheme: dark) {
  :root {
    --theme-primary-light: #eabf48;
    --theme-primary-normal: #e7b836;
    --theme-primary-dark: #e5b224;
    --theme-secondary-normal: #1c2636;
    --theme-secondary-dark: #171c26;
    --theme-tertiary: #025f7d;
    --theme-text-heading: ;
    --theme-text-normal: #fff8eb;
    --theme-text-dark: #fff1d6;
    --theme-text-inverted: #fff;
    --theme-text-primary: #fff8eb;
    --theme-text-secondary: #fff8eb;
    --theme-base: #11151c;
    --theme-error: #942e2e;
  }
}

html[data-theme="light"] {
  --theme-primary-light: #394c56;
  --theme-primary-normal: #2f3e46;
  --theme-primary-dark: #29363d;
  --theme-secondary-normal: #d2694b;
  --theme-secondary-dark: #c45131;
  --theme-tertiary: #52796f;
  --theme-text-heading: #29363d;
  --theme-text-normal: #29363d;
  --theme-text-dark: #212b31;
  --theme-text-inverted: #fff;
  --theme-text-primary: #fff;
  --theme-text-secondary: #181f23;
  --theme-base: #f4f6f3;
  --theme-error: #942e2e;
}

html[data-theme="dark"] {
  --theme-primary-light: #eabf48;
  --theme-primary-normal: #e7b836;
  --theme-primary-dark: #e5b224;
  --theme-secondary-normal: #1c2636;
  --theme-secondary-dark: #171c26;
  --theme-tertiary: #025f7d;
  --theme-text-heading: #fff8eb;
  --theme-text-normal: #fff8eb;
  --theme-text-dark: #fff1d6;
  --theme-text-inverted: #fff;
  --theme-text-primary: #fff8eb;
  --theme-text-secondary: #fff8eb;
  --theme-base: #11151c;
  --theme-error: #942e2e;
}

html[data-theme="coral-light"] {
  --theme-primary-light: #f57a7a;
  --theme-primary-normal: #f25757;
  --theme-primary-dark: #f14141;
  --theme-secondary-normal: #234257;
  --theme-secondary-dark: #1d3749;
  --theme-tertiary: #1d3749;
  --theme-text-heading: #1d3749;
  --theme-text-normal: #0c161d;
  --theme-text-dark: #060b0e;
  --theme-text-inverted: #fff;
  --theme-text-primary: #0c161d;
  --theme-text-secondary: #f5f5f5;
  --theme-base: #f5f5f5;
  --theme-error: #942e2e;
}

html[data-theme="coral-dark"] {
  --theme-primary-light: #f57a7a;
  --theme-primary-normal: #f25757;
  --theme-primary-dark: #f14141;
  --theme-secondary-normal: #172c3a;
  --theme-secondary-dark: #11212c;
  --theme-tertiary: #1d3749;
  --theme-text-heading: #f25757;
  --theme-text-normal: #f5f5f5;
  --theme-text-dark: #ebebeb;
  --theme-text-inverted: #f5f5f5;
  --theme-text-primary: #f5f5f5;
  --theme-text-secondary: #f5f5f5;
  --theme-base: #1d3749;
  --theme-error: #942e2e;
}

html[data-theme="forrest"] {
  --theme-primary-light: #b2be9d;
  --theme-primary-normal: #a3b18a;
  --theme-primary-dark: #588157;
  --theme-secondary-normal: #344e41;
  --theme-secondary-dark: #31493d;
  --theme-tertiary: #3a5a40;
  --theme-text-heading: #a3b18a;
  --theme-text-normal: #dad7cd;
  --theme-text-dark: #d4d0c4;
  --theme-text-inverted: #344e41;
  --theme-text-primary: #dad7cd;
  --theme-text-secondary: #dad7cd;
  --theme-base: #293d33;
  --theme-error: #942e2e;
}

html[data-theme="grayscale"] {
  --theme-primary-light: #6c757d;
  --theme-primary-normal: #343a40;
  --theme-primary-dark: #343a40;
  --theme-secondary-normal: #dee2e6;
  --theme-secondary-dark: #ced4da;
  --theme-tertiary: #212529;
  --theme-text-heading: #212529;
  --theme-text-normal: #212529;
  --theme-text-dark: #1b1f22;
  --theme-text-inverted: #f8f9fa;
  --theme-text-primary: #212529;
  --theme-text-secondary: #212529;
  --theme-base: #f8f9fa;
  --theme-error: #942e2e;
}

html[data-theme="pixely-2020"] {
  --theme-primary-light: #535664;
  --theme-primary-normal: #2d3142;
  --theme-primary-dark: #252937;
  --theme-secondary-normal: #9abd97;
  --theme-secondary-dark: #7f9b7c;
  --theme-tertiary: #025f7d;
  --theme-text-heading: #2f3e46;
  --theme-text-normal: #3c484e;
  --theme-text-dark: #000;
  --theme-text-inverted: #fff;
  --theme-text-primary: #3c484e;
  --theme-text-secondary: #3c484e;
  --theme-base: #f5f1de;
  --theme-error: #942e2e;
}

* {
  transition-property: background-color, color, border-color;
  transition-duration: .15s;
  transition-timing-function: ease-in-out;
}

html {
  scroll-behavior: smooth;
}

::selection {
  background: var(--theme-secondary-normal);
}

.grid {
  grid-template-columns: 4vw 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr 4vw;
  width: 100vw;
  max-width: 1200px;
  display: grid;
}

.grid__hero, .grid__content {
  grid-column: 2 / 11;
}

html {
  font-size: 18px;
}

body {
  color: var(--theme-text-normal);
  font-family: Karla, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  line-height: 1.65;
}

h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
  color: var(--theme-text-heading);
  margin: 0;
  font-family: Rubik, sans-serif;
  font-weight: bold;
  line-height: 1.15;
}

h1, .heading-1 {
  margin-top: 0;
  font-size: 2.488rem;
}

h2, .heading-2 {
  font-size: 2.074rem;
}

h3, .heading-3 {
  font-size: 1.728rem;
}

h4, .heading-4 {
  font-size: 1.44rem;
}

h5, .heading-5 {
  font-size: 1.2rem;
}

h6, .heading-6 {
  font-size: 1rem;
}

p {
  margin: 0;
}

small, .text-s {
  font-size: .833rem;
}

.text-xs {
  font-size: .694rem;
}

.text-xxs {
  font-size: .579rem;
}

.text--inverted {
  color: var(--theme-text-inverted);
}

@media (min-width: 576px) {
  html {
    font-size: 20px;
  }
}

.button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: var(--theme-secondary-normal);
  border: 2px solid var(--theme-secondary-dark);
  color: var(--theme-text-dark);
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
  font-weight: bold;
  transition: all .1s ease-in-out;
}

.button:hover, .button:focus, .button:active {
  background: var(--theme-secondary-dark);
  border-color: var(--theme-text-normal);
}

.button:focus, .button:active {
  outline-color: var(--theme-text-normal);
  transform: scale(.99);
}

.image.hero, .hero {
  object-fit: cover;
  object-position: center;
  height: 100%;
}

@media (min-width: 576px) {
  .image.hero, .hero {
    max-height: 60vh;
  }
}

.image {
  border: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.input {
  border: 1px solid var(--theme-primary-light);
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  transition: all .1s ease-in-out;
}

.input:focus, .input:active {
  border-width: 2px;
  padding: 9px;
}

.input:hover, .input:focus, .input:active {
  border-color: var(--theme-primary-normal);
  outline-color: var(--theme-primary-normal);
}

.link {
  color: currentColor;
  font-weight: var(--font-bold);
  -webkit-text-decoration: underline rgba(0, 0, 0, .3);
  text-decoration: underline rgba(0, 0, 0, .3);
  text-decoration-thickness: 1px;
  transition: all .2s linear;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
}

.link:hover, .link:focus, .link:active {
  background: rgba(0, 0, 0, .1);
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
}

.link--inverted {
  color: var(--theme-text-inverted);
  text-decoration-color: rgba(255, 255, 255, .3);
}

.link--inverted:hover, .link--inverted:focus, .link--inverted:active {
  background: rgba(255, 255, 255, .1);
  text-decoration-color: rgba(255, 255, 255, .1);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
}

@media (min-width: 576px) {
  .link {
    text-decoration-thickness: 2px;
  }
}

.logo {
  color: var(--theme-text-inverted);
  letter-spacing: -1px;
  margin: 0;
  font-family: Karla, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.logo__link {
  background: var(--theme-primary-normal);
  color: currentColor;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 20px;
  line-height: 0;
  text-decoration: none;
  transition: background-color .2s linear;
  display: flex;
}

.logo__link:hover, .logo__link:active, .logo__link:focus {
  background: var(--theme-primary-dark);
}

.textarea {
  border: 1px solid var(--theme-primary-light);
  border-radius: 4px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 800px;
  padding: 10px;
  transition: all .1s ease-in-out;
}

.textarea:focus, .textarea:active {
  border-width: 2px;
  padding: 9px;
}

.textarea:hover, .textarea:focus, .textarea:active {
  border-color: var(--theme-primary-normal);
  outline-color: var(--theme-primary-normal);
}

.title {
  background: var(--theme-primary-normal);
  color: var(--theme-text-inverted);
  padding: 20px;
}

.author-bio {
  grid-gap: 10px;
  grid-template-columns: 1fr 4fr;
  display: grid;
}

.author-bio__image {
  width: 100%;
  display: block;
}

.author-bio__name {
  margin-bottom: 10px;
  text-decoration: none;
}

.author-bio__link:not(:last-child) {
  margin-right: 20px;
}

.author-bio--boxed {
  background: var(--theme-primary-normal);
  color: var(--theme-text-inverted);
  padding: 20px;
}

.author-bio--boxed .author-bio__name {
  color: var(--theme-text-inverted);
}

@media (min-width: 576px) {
  .author-bio {
    grid-gap: 20px;
  }
}

.nav {
  background: var(--theme-secondary-normal);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  z-index: 1;
  border: 0;
  width: 60px;
  height: 60px;
  padding: 0;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
  transform: rotate(0);
}

.nav--theme {
  background: rgba(var(--theme-secondary-normal) / .8);
  padding: 18px;
}

.nav--theme svg {
  width: 100%;
  height: 100%;
}

.nav--theme path {
  fill: var(--theme-text-secondary);
  transition: all .3s ease-in-out;
}

.nav--theme:hover path, .nav--theme:active path, .nav--theme:focus path {
  stroke: rgba(0, 0, 0, 0);
}

.nav.is-open {
  transform: translateY(10px);
}

.nav__icon-bar {
  background: var(--theme-text-secondary);
  opacity: 1;
  width: 24px;
  height: 3px;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
  left: 18px;
  transform: rotate(0);
}

.nav__icon-bar:first-child {
  top: 21px;
}

.nav__icon-bar:nth-child(2), .nav__icon-bar:nth-child(3) {
  top: 28px;
}

.nav__icon-bar:nth-child(4) {
  top: 35px;
}

.nav.is-open .nav__icon-bar:first-child, .nav.is-open .nav__icon-bar:nth-child(4) {
  width: 0%;
  top: 28px;
  left: 50%;
}

.nav.is-open .nav__icon-bar:nth-child(2) {
  transform: rotate(45deg);
}

.nav.is-open .nav__icon-bar:nth-child(3) {
  transform: rotate(-45deg);
}

.nav:not(.nav--theme).is-open, .nav:hover, .nav:active, .nav:focus {
  background: var(--theme-secondary-dark);
}

.nav__overlay {
  cursor: pointer;
  background: rgba(0, 0, 0, .75);
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav__overlay.is-open {
  display: block;
}

.nav__overlay.is-animating-in {
  animation: .2s cubic-bezier(.42, 0, .17, 1.24) nav-fade-in;
  display: block;
}

.nav__overlay.is-animating-out {
  animation: .2s ease-in nav-fade-out;
  display: block;
}

.nav__menu {
  background: var(--theme-secondary-normal);
  border-top: 10px solid var(--theme-secondary-dark);
  border-left: 10px solid var(--theme-secondary-dark);
  transform-origin: 0 0;
  -webkit-overflow-scrolling: touch;
  flex: 1px;
  height: 100%;
  padding: 60px 10px;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
}

.nav__menu.is-open {
  display: block;
}

.nav__menu.is-animating-in {
  animation: .2s linear nav-slide-in-down;
  display: block;
}

.nav__menu.is-animating-out {
  animation: .2s ease-in-out nav-slide-out-up;
  display: block;
}

.nav__menu-item {
  color: var(--theme-text-secondary);
  text-align: center;
  background: none;
  align-content: center;
  justify-content: center;
  padding: 20px 0;
  font-size: 1.44rem;
  font-weight: bold;
  text-decoration: none;
  display: grid;
}

.nav__menu-item:last-child {
  margin-bottom: 20px;
}

.nav__menu-item:not(:last-child) {
  border-bottom: 1px solid var(--theme-secondary-dark);
}

.nav__menu-item:hover {
  background: var(--theme-secondary-dark);
}

@media (min-width: 576px) {
  .nav.is-open {
    transform: translateY(20px);
  }

  .nav__menu {
    border-top: 0;
    border-left-width: 10px;
    align-content: start;
    width: 30vw;
    padding-top: 90px;
    display: none;
    bottom: 0;
    left: auto;
  }

  .nav__menu.is-open {
    display: block;
  }

  .nav__menu.is-animating-in {
    animation: .2s linear nav-slide-in;
    display: block;
  }

  .nav__menu.is-animating-out {
    animation: .2s ease-in-out nav-slide-out;
    display: block;
  }
}

@keyframes nav-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes nav-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes nav-slide-in {
  from {
    opacity: .5;
    transform: translate(100%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes nav-slide-out {
  from {
    opacity: 1;
    transform: translate(0);
  }

  to {
    opacity: .5;
    transform: translate(100%);
  }
}

@keyframes nav-slide-in-down {
  from {
    transform: translate(-100%, -100%)scale(0);
  }

  to {
    transform: translate(0)scale(1);
  }
}

@keyframes nav-slide-out-up {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.pagination {
  grid-gap: 20px;
  grid-template-columns: 2fr 2fr 2fr;
  align-items: center;
  display: grid;
}

.pagination__prev {
  grid-column-start: 1;
}

.pagination__count {
  grid-column-start: 2;
  justify-self: center;
}

.pagination__next {
  grid-column-start: 3;
  justify-self: right;
}

.post-meta {
  padding-bottom: 5px;
}

.post-meta__row {
  color: var(--theme-text-secondary);
  text-align: right;
  padding: 5px 10px;
  font-family: Karla, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: .694rem;
  line-height: 1.5;
}

.post-meta__row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}

@media (min-width: 576px) {
  .post-meta {
    padding: 0;
  }

  .post-meta__row {
    padding: 10px;
  }
}

.related-posts__title {
  margin-bottom: 10px;
}

.related-posts__items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.related-posts__item:not(:last-child) {
  margin-bottom: 10px;
}

.card {
  color: var(--theme-text-normal);
  grid-template-columns: 1fr;
  text-decoration: none;
  transition: transform .15s ease-in-out;
  display: grid;
}

.card__body {
  padding: 10px;
}

.card__meta {
  margin: 5px 0 10px;
  font-family: Karla, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: .694rem;
}

.card__meta-item:not(:last-child):after {
  content: " – ";
}

.card--featured {
  background: var(--theme-primary-normal);
  color: var(--theme-text-inverted);
}

@media (min-width: 576px) {
  .card {
    grid-gap: 20px;
    grid-template-columns: 2fr 6fr;
    margin: -10px;
    padding: 10px;
  }

  .card:hover {
    transform: scale(1.025);
  }

  .card__body {
    grid-column-start: 2;
    padding: 0;
  }
}

.contact-form__field:not(:last-child) {
  margin-bottom: 15px;
}

.contact-form__label {
  display: block;
}

@media (min-width: 576px) {
  .contact-form__field--narrow {
    width: 75%;
  }
}

.content {
  grid-gap: 10px;
  grid-template-columns: 10px 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr 10px;
  display: grid;
}

.content > * {
  grid-column: 2 / -2;
}

.content > .kg-width-wide {
  grid-column: 2 / -1;
}

.content > .kg-width-full {
  grid-column: 1 / -1;
}

.content > .kg-width-full figcaption {
  padding: 0 20px;
}

.content img {
  border: 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.content figure {
  margin: 0;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: var(--theme-primary-normal);
}

.content h1 {
  margin-top: 2.488rem;
}

.content h2 {
  margin-top: 2.074rem;
}

.content h3 {
  margin-top: 1.728rem;
}

.content h4 {
  margin-top: 1.44rem;
}

.content h5 {
  margin-top: 1.2rem;
}

.content blockquote {
  margin: 0;
  font-family: Rubik, sans-serif;
  font-size: 1.44rem;
  font-style: italic;
  font-weight: 400;
}

.content a {
  color: var(--theme-text-normal);
  word-break: break-word;
  font-weight: 700;
  -webkit-text-decoration: underline rgba(0, 0, 0, .3);
  text-decoration: underline rgba(0, 0, 0, .3);
  text-decoration-thickness: 2px;
  transition: all .2s linear;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
}

.content a:hover, .content a:focus, .content a:active {
  background: rgba(0, 0, 0, .1);
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
}

.content li {
  margin-bottom: 5px;
}

.content pre {
  white-space: pre-wrap;
  background: var(--theme-text-normal);
  color: var(--theme-base);
  border-radius: 5px;
  margin: 0;
  padding: 20px;
  font-size: .694rem;
  overflow-x: auto;
}

.content code {
  font-family: Anonymous Pro, monospace;
}

.content pre code {
  color: var(--theme-base);
}

.content hr {
  border: 0;
  border-top: 3px solid var(--theme-primary-normal);
  margin: 0;
}

.content ul {
  margin: 0;
}

.content p > img, .content figure > iframe {
  max-width: 100%;
  display: block;
  margin: 0 !important;
}

.content figcaption {
  font-size: .694rem;
}

.content mark {
  background: var(--theme-secondary-normal);
  color: var(--theme-text-dark);
}

.content .kg-embed-card {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.content .kg-embed-card iframe, .content .kg-embed-card object, .content .kg-embed-card embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.content dl {
  grid-column: 2 / -2;
  grid-template-columns: max-content 1fr;
  margin: 0;
  font-size: .833rem;
  display: grid;
}

.content dt {
  padding-right: 5px;
  font-weight: bold;
}

.content dd {
  padding-left: 5px;
}

.content dt, .content dd {
  margin: 0;
}

.content dt:not(:first-of-type), .content dd:not(:first-of-type) {
  padding-top: 10px;
}

.content dt:not(:last-of-type), .content dd:not(:last-of-type) {
  border-bottom: 1px solid var(--theme-primary-light);
  padding-bottom: 10px;
}

.content table {
  border-collapse: collapse;
}

.content th, .content td {
  text-align: left;
  vertical-align: top;
  padding: 10px;
  font-size: .833rem;
}

.content thead th {
  border-bottom: 2px solid var(--theme-secondary-dark);
}

.content th:not(:last-child), .content td:not(:last-child) {
  border-right: 1px solid var(--theme-secondary-normal);
}

.content tr:not(:last-child) td {
  border-bottom: 1px solid var(--theme-secondary-normal);
}

@media (min-width: 576px) {
  .content {
    grid-gap: 20px;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  }

  .content > * {
    grid-column: 3 / -3;
  }

  .content > .kg-width-wide {
    grid-column: 3 / -1;
  }

  .content dl {
    grid-column: 3 / -2;
  }
}

.feed {
  grid-gap: 20px;
  display: grid;
}

.feed__pagination {
  margin: 20px 0 40px;
}

.footer {
  background: var(--theme-secondary-normal);
  grid-gap: 10px;
  grid-template-columns: 1fr;
  height: 100%;
  padding: 10px 0;
  display: grid;
}

.footer__bio, .footer__nav {
  grid-column: 1 / -1;
}

.footer__nav {
  grid-row: 1;
}

.footer__nav-item {
  color: var(--theme-text-secondary);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  font-weight: 700;
  display: block;
}

.footer__nav-item:hover {
  color: var(--theme-text-dark);
}

@media (min-width: 576px) {
  .footer {
    grid-gap: 20px;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
    padding: 20px 0 0;
    display: grid;
  }

  .footer__bio {
    grid-column: 5 / span 4;
  }

  .footer__nav {
    grid-area: 1 / 2 / auto / span 3;
  }

  .footer__nav-item {
    padding: 0;
    display: inline-block;
  }

  .footer__nav-item:not(:last-child) {
    margin-right: 10px;
  }
}

.header {
  grid-gap: 10px;
  z-index: 2;
  grid-template-columns: 10px 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr 10px;
  display: grid;
}

.header__logo {
  grid-column: 1 / 6;
}

.header__nav {
  grid-auto-flow: column;
  grid-column-start: -2;
  justify-self: right;
  display: grid;
}

@media (min-width: 576px) {
  .header {
    grid-gap: 20px;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  }

  .header__logo {
    grid-column-end: auto;
  }
}

.masthead {
  z-index: 4;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.masthead__logo a {
  color: var(--theme-base);
  z-index: 5;
  letter-spacing: -.8px;
  align-items: center;
  height: 55px;
  font-family: Karla, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  display: flex;
  position: relative;
  left: 20px;
}

.masthead:before {
  background: var(--theme-primary-normal);
  content: "";
  transform-origin: 0 0;
  z-index: 2;
  height: 55px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: skewY(-2deg);
}

.masthead--background {
  z-index: 2;
}

.masthead--background:before {
  background: var(--color--tertiary);
  content: "";
  transform-origin: 0 0;
  z-index: 0;
  height: 65px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: skewY(-4deg);
}

.masthead--background:after {
  background: var(--theme-secondary-normal);
  content: "";
  transform-origin: 100% 0;
  z-index: 1;
  height: 60px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: skewY(2deg);
}

.masthead--shadows {
  z-index: 1;
}

.masthead--shadows:before {
  background: var(--color--tertiary);
  content: "";
  transform-origin: 0 0;
  z-index: 0;
  opacity: .5;
  height: 70px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: skewY(-5deg);
}

.masthead--shadows:after {
  background: var(--theme-secondary-normal);
  content: "";
  transform-origin: 100% 0;
  z-index: 1;
  opacity: .5;
  height: 70px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: skewY(4deg);
}

@keyframes active-theme {
  0%, 100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .5);
  }

  40% {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, .5);
  }
}

.theme-drawer {
  background: var(--theme-secondary-dark);
  text-align: center;
  z-index: 200;
  border-bottom: 2px solid rgba(0, 0, 0, .2);
  max-height: 0;
  display: none;
  position: sticky;
  top: 0;
}

.theme-drawer.is-open {
  animation: .4s cubic-bezier(.42, 0, .17, 1.24) both theme-drawer-open;
  display: block;
}

.theme-drawer.is-animating-out {
  animation: .2s ease-in both theme-drawer-close;
  display: block;
}

.theme-drawer__themes {
  -webkit-overflow-scrolling: touch;
  padding: 5px 10px;
  overflow-x: scroll;
}

.theme-drawer__cards {
  width: -moz-fit-content;
  width: fit-content;
  margin: 15px auto 5px;
  display: flex;
}

.theme-drawer__card {
  text-align: center;
  background: rgba(0, 0, 0, .1) padding-box padding-box;
  border-radius: 4px;
  width: 150px;
  margin: 0 10px;
  position: relative;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, .1);
}

.theme-drawer__card--light .theme-drawer__checkbox:focus ~ .theme-drawer__label {
  border-color: #29363d;
}

.theme-drawer__card--dark .theme-drawer__checkbox:focus ~ .theme-drawer__label {
  border-color: #e5b224;
}

.theme-drawer__card--coral-light .theme-drawer__checkbox:focus ~ .theme-drawer__label, .theme-drawer__card--coral-dark .theme-drawer__checkbox:focus ~ .theme-drawer__label {
  border-color: #f14141;
}

.theme-drawer__card--forrest .theme-drawer__checkbox:focus ~ .theme-drawer__label {
  border-color: #588157;
}

.theme-drawer__card--grayscale .theme-drawer__checkbox:focus ~ .theme-drawer__label {
  border-color: #343a40;
}

.theme-drawer__card--pixely-2020 .theme-drawer__checkbox:focus ~ .theme-drawer__label {
  border-color: #252937;
}

.theme-drawer__label {
  color: #29363d;
  text-transform: capitalize;
  background-color: #f4f6f3;
  background-image: linear-gradient(90deg, #394c56 17%, #2f3e46 17%, #2f3e46 33%, #29363d 33%, #29363d 50%, #d2694b 50%, #d2694b 75%, #c45131 75%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 10px;
  border: 2px solid #f4f6f3;
  border-radius: 4px;
  padding: 10px 10px 20px;
  line-height: 1;
  transition: box-shadow .2s ease-in-out, transform .2s ease-in-out, border-color .2s ease-in-out;
  display: block;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, .2);
}

@media (prefers-color-scheme: dark) {
  .theme-drawer__label {
    color: #fff8eb;
    background-color: #11151c;
    background-image: linear-gradient(90deg, #eabf48 17%, #e7b836 17%, #e7b836 33%, #e5b224 33%, #e5b224 50%, #1c2636 50%, #1c2636 75%, #171c26 75%);
    border-color: #11151c;
  }

  .theme-drawer__label--default:after {
    content: ": Dark";
  }
}

.theme-drawer__label--light {
  color: #29363d;
  background-color: #f4f6f3;
  background-image: linear-gradient(90deg, #394c56 17%, #2f3e46 17%, #2f3e46 33%, #29363d 33%, #29363d 50%, #d2694b 50%, #d2694b 75%, #c45131 75%);
  border-color: #f4f6f3;
}

.theme-drawer__label--dark {
  color: #fff8eb;
  background-color: #11151c;
  background-image: linear-gradient(90deg, #eabf48 17%, #e7b836 17%, #e7b836 33%, #e5b224 33%, #e5b224 50%, #1c2636 50%, #1c2636 75%, #171c26 75%);
  border-color: #11151c;
}

.theme-drawer__label--coral-light {
  color: #0c161d;
  background-color: #f5f5f5;
  background-image: linear-gradient(90deg, #f57a7a 17%, #f25757 17%, #f25757 33%, #f14141 33%, #f14141 50%, #234257 50%, #234257 75%, #1d3749 75%);
  border-color: #f5f5f5;
}

.theme-drawer__label--coral-dark {
  color: #f5f5f5;
  background-color: #1d3749;
  background-image: linear-gradient(90deg, #f57a7a 17%, #f25757 17%, #f25757 33%, #f14141 33%, #f14141 50%, #172c3a 50%, #172c3a 75%, #11212c 75%);
  border-color: #1d3749;
}

.theme-drawer__label--forrest {
  color: #dad7cd;
  background-color: #293d33;
  background-image: linear-gradient(90deg, #b2be9d 17%, #a3b18a 17%, #a3b18a 33%, #588157 33%, #588157 50%, #344e41 50%, #344e41 75%, #31493d 75%);
  border-color: #293d33;
}

.theme-drawer__label--grayscale {
  color: #212529;
  background-color: #f8f9fa;
  background-image: linear-gradient(90deg, #6c757d 17%, #343a40 17%, #343a40 33%, #343a40 33%, #343a40 50%, #dee2e6 50%, #dee2e6 75%, #ced4da 75%);
  border-color: #f8f9fa;
}

.theme-drawer__label--pixely-2020 {
  color: #3c484e;
  background-color: #f5f1de;
  background-image: linear-gradient(90deg, #535664 17%, #2d3142 17%, #2d3142 33%, #252937 33%, #252937 50%, #9abd97 50%, #9abd97 75%, #7f9b7c 75%);
  border-color: #f5f1de;
}

.theme-drawer__label--default:after {
  content: ": Light";
}

.theme-drawer__checkbox {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.theme-drawer__checkbox:focus ~ .theme-drawer__label {
  border-color: var(--theme-primary-dark);
}

.theme-drawer__checkbox:hover ~ .theme-drawer__label {
  transform: scale(1.01);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, .5);
}

.theme-drawer__checkbox:active ~ .theme-drawer__label {
  transform: scale(.98);
}

.theme-drawer__checkbox:checked ~ .theme-drawer__label {
  font-weight: 700;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, .5);
}

.theme-drawer__checkbox:checked:not(:hover) ~ .theme-drawer__label {
  animation: 1.3s ease-in-out infinite active-theme;
}

.theme-drawer__close {
  border: unset;
  cursor: pointer;
  color: var(--theme-primary-normal);
  background: none;
  margin-bottom: 10px;
  padding: 5px 5px 5px calc(5px + .694rem);
  position: relative;
}

.theme-drawer__close:before, .theme-drawer__close:after {
  content: "";
  background: var(--theme-primary-normal);
  opacity: 1;
  width: .694rem;
  height: 2px;
  display: block;
  position: absolute;
  top: 11.5px;
  left: 0;
}

.theme-drawer__close:before {
  transform: rotate(45deg);
}

.theme-drawer__close:after {
  transform: rotate(-45deg);
}

.theme-drawer__close:hover {
  color: var(--theme-primary-dark);
  text-decoration: underline;
}

.theme-drawer__close:hover:before, .theme-drawer__close:hover:after {
  background: var(--theme-primary-dark);
}

@media (min-width: 576px) {
  .theme-drawer__card {
    width: 200px;
  }
}

@keyframes theme-drawer-open {
  0% {
    opacity: 0;
    max-height: 0;
    transform: translateY(-200px);
  }

  40% {
    opacity: 0;
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    max-height: 150px;
    transform: translateY(0);
  }
}

@keyframes theme-drawer-close {
  0% {
    opacity: 1;
    max-height: 150px;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

.author__title {
  z-index: 1;
  grid-area: 3 / 2 / span 2 / -2;
  margin-left: -20px;
}

.author__bio {
  grid-gap: 20px;
  grid-column: 2 / -2;
  grid-template-columns: 2fr 6fr;
  display: grid;
}

.author__profile-image {
  grid-row-end: span 2;
}

.author__image {
  background: linear-gradient(45deg, var(--theme-tertiary), var(--theme-primary-dark));
  object-fit: cover;
  object-position: center;
  grid-area: 1 / 1 / span 3 / -1;
  height: 100%;
}

.author__feed {
  border-top: 3px solid var(--theme-primary-normal);
  grid-area: auto / 2 / span 2 / -2;
  padding-top: 20px;
}

@media (min-width: 576px) {
  .author__title {
    grid-column: 2 / span 4;
    margin-left: -20px;
  }

  .author__feed {
    grid-column: 2 / span 6;
  }
}

@media (min-width: 1200px) {
  .author__feed {
    grid-column: 2 / span 5;
  }
}

.base {
  background: var(--theme-secondary-normal);
}

.base__inner {
  background: var(--theme-base);
  grid-gap: 10px;
  grid-template-rows: min-content auto;
  grid-template-columns: 10px 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr 10px;
  min-height: calc(100vh - 20px);
  margin: 10px 0 0 10px;
  display: grid;
}

.base--post .base__inner {
  grid-template-rows: 60px 25vh 60px;
}

.base--tag .base__inner, .base--author .base__inner {
  grid-template-rows: 60px 25vh 45px minmax(20px, max-content) auto;
}

.base__header {
  z-index: 10;
  grid-column: 1 / -1;
  grid-row-start: 1;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.base.is-locked {
  height: 100vh;
  overflow: hidden;
}

.base__footer {
  grid-column: 1 / -1;
}

@media (min-width: 576px) {
  .base__inner {
    grid-gap: 20px;
    grid-template-rows: min-content auto;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
    min-height: calc(100vh - 40px);
    margin: 20px;
  }

  .base--post .base__inner {
    grid-template-rows: auto;
  }
}

.home__feed {
  grid-column: 2 / -2;
  margin-top: 10px;
}

@media (min-width: 576px) {
  .home__feed {
    grid-column: 2 / span 6;
  }
}

@media (min-width: 1200px) {
  .home__feed {
    grid-column: 2 / span 5;
  }
}

.post__title {
  z-index: 1;
  grid-area: 3 / 2 / 5 / -1;
}

.post__hero {
  background: var(--theme-primary-light);
  grid-area: 1 / 1 / 4 / -1;
}

.post__meta {
  background: var(--theme-secondary-normal);
  grid-column: 1 / span 8;
  grid-row-start: 5;
}

.post__meta--cosmetic {
  grid-row-start: 4;
  margin-bottom: -10px;
  padding: 20px;
}

.post__content {
  grid-column: 1 / -1;
}

.post__footer {
  grid-gap: 20px;
  grid-column: 2 / -2;
  margin: 40px 0;
  display: grid;
}

@media (min-width: 576px) {
  .post__title {
    grid-area: 3 / 2 / 5 / 6;
  }

  .post__hero {
    grid-column: 3 / -1;
  }

  .post__meta {
    grid-column: 1 / span 2;
    align-self: start;
  }

  .post__meta--cosmetic {
    margin-bottom: -20px;
  }

  .post__content {
    grid-row-start: 5;
  }

  .post__footer {
    grid-column: 1 / -1;
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }

  .post__footer > :first-child {
    grid-column-start: 2;
  }
}

.tag__title {
  z-index: 1;
  grid-area: 3 / 2 / span 2 / -2;
  margin-left: -20px;
}

.tag__description {
  grid-column: 2 / -2;
}

.tag__image {
  background: linear-gradient(45deg, var(--theme-tertiary), var(--theme-primary-dark));
  object-fit: cover;
  object-position: center;
  grid-area: 1 / 1 / span 3 / -1;
  height: 100%;
}

.tag__feed {
  grid-area: auto / 2 / span 2 / -2;
}

@media (min-width: 576px) {
  .tag__title {
    grid-column: 2 / span 4;
    margin-left: -20px;
  }

  .tag__feed {
    grid-column: 2 / span 6;
  }
}

@media (min-width: 1200px) {
  .tag__feed {
    grid-column: 2 / span 5;
  }
}

/*# sourceMappingURL=styles.css.map */
