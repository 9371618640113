.textarea {
  padding: 10px;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 200px;
  max-height: 800px;
  border: 1px solid var(--theme-primary-light);
  border-radius: 4px;
  transition: all .1s ease-in-out;

  &:focus,
  &:active {
    border-width: 2px;
    padding: 9px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: var(--theme-primary-normal);
    outline-color: var(--theme-primary-normal);
  }

}

