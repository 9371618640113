.input {
  border: 1px solid var(--theme-primary-light);
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  transition: all .1s ease-in-out;

  &:focus,
  &:active {
    border-width: 2px;
    padding: 9px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: var(--theme-primary-normal);
    outline-color: var(--theme-primary-normal);
  }

}

