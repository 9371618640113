.content {
  display: grid;
  grid-template-columns: $grid-mobile-template;
  grid-gap: $grid-mobile-gap;

  & > * {
    grid-column: 2 / -2;
  }

  & > .kg-width-wide {
    grid-column: 2 / -1;
  }

  & > .kg-width-full {
    grid-column: 1 / -1;
  }

  & > .kg-width-full figcaption {
    padding: 0 20;
  }

  img {
    margin: 0;
    border: 0;
    max-width: 100%;
    width: 100%;
  }

  figure {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--theme-primary-normal);
  }

  h1 {
    margin-top: 2.488rem;
  }

  h2 {
    margin-top: 2.074rem;
  }

  h3 {
    margin-top: 1.728rem;
  }

  h4 {
    margin-top: 1.44rem;
  }

  h5 {
    margin-top: 1.2rem;
  }

  blockquote {
    font-family: $font-family-heading;
    font-weight: 400;
    font-style: italic;
    font-size: 1.44rem;
    margin: 0;
  }

  a {
    color: var(--theme-text-normal);
    font-weight: $font-weight-bold;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: rgba(0 0 0 / .3);
    transition: all linear .2s;
    box-shadow: 0 0 0 2px transparent;
    word-break: break-word;

    &:hover,
    &:focus,
    &:active {
      background: rgba(0 0 0 / .1);
      box-shadow: 0 0 0 2px rgba(0 0 0 / .1);
      text-decoration: none;
      text-decoration-color: rgba(0 0 0 / .1);
    }
  }

  li { 
    margin-bottom: 5px;
  }
  
  pre {
    margin: 0;
    white-space: pre-wrap;
    padding: 20;
    background: var(--theme-text-normal);
    color: var(--theme-base);
    border-radius: 5px;
    font-size: .694rem;
    overflow-x: auto;
  }

  code {
    font-family: $font-family-monospace;
  }

  pre code {
    color: var(--theme-base);
  }

  hr {
    border: 0;
    border-top: 3px solid var(--theme-primary-normal);
    margin: 0;
  }

  ul {
    margin: 0;
  }

  p > img,
  figure > iframe {
    display: block;
    margin: 0 !important; // Instragram embed adds a margin, let's remove it
    max-width: 100%;
  }

  /* stylelint-disable-next-line no-descending-specificity */ 
  figcaption {
    font-size: .694rem;
  }

  mark {
    background: var(--theme-secondary-normal);
    color: var(--theme-text-dark);
  }

  .kg-embed-card {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .kg-embed-card iframe,
  .kg-embed-card object,
  .kg-embed-card embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & dl {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column: 2 / -2;
    margin: 0;
    font-size: .833rem;
  }

  & dt {
    padding-right: 5px;
    font-weight: bold;
  }

  & dd {
    padding-left: 5px;
  }

  & dt,
  & dd {
    margin: 0;

    &:not(:first-of-type) {
      padding-top: 10;
    }

    &:not(:last-of-type) {
      padding-bottom: 10;
      border-bottom: 1px solid var(--theme-primary-light);
    }
  }

  table {
    border-collapse: collapse;
  }

  th,
  td {
    text-align: left;
    padding: 10;
    font-size: .833rem;
    vertical-align: top;
  }

  thead th {
    border-bottom: 2px solid var(--theme-secondary-dark);
  }
  
  th:not(:last-child),
  td:not(:last-child) {
    border-right: 1px solid var(--theme-secondary-normal);
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid var(--theme-secondary-normal);
  }
  
}

@media (min-width: 576px) {
  .content {
    grid-template-columns: $grid-desktop-template;
    grid-gap: $grid-desktop-gap;

    & > * {
      grid-column: 3 / -3;
    }

    & > .kg-width-wide {
      grid-column: 3 / -1;
    }

    & dl {
      grid-column: 3 / -2;
    }
  }
}
