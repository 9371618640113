.post {
  &__title {
    grid-column: 2 / -1;
    grid-row: 3 / 5;
    z-index: 1;
  }

  &__hero {
    grid-column: 1 / -1;
    grid-row: 1 / 4;
    background: var(--theme-primary-light);
  }

  &__meta {
    background: var(--theme-secondary-normal);
    grid-column: 1 / span 8;
    grid-row-start: 5;

    &--cosmetic {
      grid-row-start: 4;
      padding: $grid-desktop-gap;
      margin-bottom: -#{$grid-mobile-gap};
    }
  }

  &__content {
    grid-column: 1 / -1;
  }

  &__footer {
    grid-column: 2 / -2;
    margin: 40px 0;
    display: grid;
    grid-gap: $grid-desktop-gap;
  }
}

@media (min-width: 576px) {
  .post {
    &__title {
      grid-column: 2 / 6;
      grid-row: 3 / 5;
    }

    &__hero {
      grid-column: 3 / -1;
    }

    &__meta {
      grid-column: 1 / span 2;
      align-self: start;
      
      &--cosmetic {
        margin-bottom: -#{$grid-desktop-gap};
      }
    }

    &__content {
      grid-row-start: 5;
    }

    &__footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: $grid-desktop-template;
      grid-template-columns: repeat(4, 1fr);

      & > *:first-child {
        grid-column-start: 2;
      }
    }
  }
}
