.related-posts {
  &__title {
    margin-bottom: 10px;
  }

  &__items {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item:not(:last-child) {
    margin-bottom: 10px;
  }
}