.pagination {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 2fr 2fr;
  align-items: center;

  &__prev {
    grid-column-start: 1;
  }

  &__count {
    grid-column-start: 2;
    justify-self: center;
  }

  &__next {
    grid-column-start: 3;
    justify-self: right;
  }
}