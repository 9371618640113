.nav {
  background: var(--theme-secondary-normal);
  display: flex;
  padding: 0;
  appearance: none;
  border: 0;
  cursor: pointer;
  z-index: 1;
  width: 60px;
  height: 60px;
  position: relative;
  transform: rotate(0deg);
  transition: .3s ease-in-out;
  
  &--theme {
    background: rgba(var(--theme-secondary-normal) / .8);
    padding: 18px;

    svg {
      height: 100%;
      width: 100%;
    }

    path {
      fill: var(--theme-text-secondary);
      transition: .3s ease-in-out;
    }
    
    &:hover path,
    &:active path,
    &:focus path {
      stroke: transparent;
    }
  }

  &.is-open {
    transform: translateY(10px)
  }

  &__icon-bar {
    display: block;
    position: absolute;
    height: 3px;
    width: 24px;
    background: var(--theme-text-secondary);
    opacity: 1;
    left: 18px;
    transform: rotate(0deg);
    transition: .25s ease-in-out; 
    
    &:nth-child(1) {
      top: 21px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 28px;
    }

    &:nth-child(4) {
      top: 35px;
    }
  }

  &.is-open .nav__icon-bar {
    &:nth-child(1),
    &:nth-child(4) {
      top: 28px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }

  &:not(.nav--theme).is-open,
  &:hover,
  &:active,
  &:focus {
    background: var(--theme-secondary-dark);
  }

  &__overlay {
    position: fixed;
    inset: 0;
    background: rgba(0 0 0 / .75);
    cursor: pointer;
    display: none;
    
    &.is-open {
      display: block;
    }

    &.is-animating-in {
      display: block;
      animation: nav-fade-in .2s cubic-bezier(.42, 0, .17, 1.24);
    }

    &.is-animating-out {
      display: block;
      animation: nav-fade-out .2s ease-in;
    }
  }

  &__menu {
    position: fixed;
    inset: 0;
    height: 100%;
    background: var(--theme-secondary-normal);
    overflow: scroll;
    padding: 60px 10px;
    flex: 1 1 1;
    border-top: 10px solid  var(--theme-secondary-dark);
    border-left: 10px solid var(--theme-secondary-dark);
    transform-origin: top left;
    -webkit-overflow-scrolling: touch;
    display: none;

    &.is-open {
      display: block;
    }

    &.is-animating-in {
      display: block;
      animation: nav-slide-in-down .2s linear;
    }

    &.is-animating-out {
      display: block;
      animation: nav-slide-out-up .2s ease-in-out;
    }
  }

  &__menu-item {
    color: var(--theme-text-secondary);
    font-weight: bold;
    text-decoration: none;
    font-size: 1.44rem;
    padding: 20px 0;
    text-align: center;
    display: grid;
    place-content: center center;
    background: transparent;

    &:last-child {
      margin-bottom: 20px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--theme-secondary-dark);;
    }

    &:hover {
      background: var(--theme-secondary-dark);
    }
  }
}

@media (min-width: 576px) {
  .nav {
    &.is-open {
      transform: translateY(20px)
    }

    &__menu {
      left: auto;
      bottom: 0;
      border-top: 0;
      border-left-width: 10px;
      width: 30vw;  
      padding-top: 90px;
      align-content: start;
      display: none;

      &.is-open {
        display: block;
      }

      &.is-animating-in {
        display: block;
        animation: nav-slide-in .2s linear;
      }

      &.is-animating-out {
        display: block;
        animation: nav-slide-out .2s ease-in-out;
      }
    }
  }
}

@keyframes nav-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes nav-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes nav-slide-in {
  from {
    transform: translate(100%);
    opacity: .5;
  }

  to {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes nav-slide-out {
  from {
    transform: translate(0);
    opacity: 1;
  }

  to {
    transform: translate(100%);
    opacity: .5;
  }
}

@keyframes nav-slide-in-down {
  from {
    transform: translate(-100%, -100%) scale(0);
  }

  to {
    transform: translate(0) scale(1);
  }
}

@keyframes nav-slide-out-up {
  from {
    opacity: 1;   
  }

  to {
    opacity: 0;
  }
}