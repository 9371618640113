.header {
  display: grid;
  grid-template-columns: $grid-mobile-template;
  grid-gap: $grid-mobile-gap;
  z-index: 2;

  &__logo {
    grid-column: 1 / 6;    
  }

  &__nav {
    grid-column-start: -2;
    justify-self: right;
    display: grid;
    grid-auto-flow: column;
  }
}


@media (min-width: 576px) {
  .header {
    grid-template-columns: $grid-desktop-template;
    grid-gap: $grid-desktop-gap;

    &__logo {
      grid-column-end: auto;
    }
  }      
}