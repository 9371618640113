.logo {
  margin: 0;
  color: var(--theme-text-inverted);
  font-family: $font-family-body-copy;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -1px;

  &__link {
    background: var(--theme-primary-normal);
    color: currentcolor;
    padding: 20px;
    text-decoration: none;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 0;
    transition: background-color .2s linear;

    &:hover,
    &:active,
    &:focus {
      background: var(--theme-primary-dark);
    }
  }
}