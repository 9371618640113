.post-meta {
  padding-bottom: 5px;

  &__row {
    font-family: $font-family-body-copy;
    font-size: .694rem;
    line-height: 1.5;
    padding: 5px 10px;
    color: var(--theme-text-secondary);
    text-align: right;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0 0 0 / .05);
    }
  }
}

@media (min-width: 576px) {
  .post-meta {
    padding: 0;

    &__row {
      padding: $size-spacing-small;
    }
  }
}