.tag {
  &__title {
    grid-column: 2 / -2;
    grid-row: 3 / span 2;
    margin-left: -20px;
    z-index: 1;
  }

  &__description {
    grid-column: 2 / -2;
  }

  &__image {
    background: linear-gradient(45deg, var(--theme-tertiary), var(--theme-primary-dark));
    grid-row: 1 / span 3;
    grid-column: 1 / -1;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  &__feed {
    grid-column: 2 / -2;
    grid-row: auto / span 2;
  }
}
  
@media (min-width: 576px) {
  .tag {
    &__title {
      grid-column: 2 / span 4;
      margin-left: -20px;
    }

    &__feed {
      grid-column: 2 / span 6;
    }
  }
}
  
@media (min-width: 1200px) {
  .tag {
    &__feed {
      grid-column: 2 / span 5;
    }
  }
}
