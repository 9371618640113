
/**
 * Do not edit directly
 * Generated on Tue, 01 Apr 2025 08:49:25 GMT
 */

$dark-theme-primary-light: #eabf48 !default;
$dark-theme-primary-normal: #e7b836 !default;
$dark-theme-primary-dark: #e5b224 !default;
$dark-theme-secondary-normal: #1c2636 !default;
$dark-theme-secondary-dark: #171c26 !default;
$dark-theme-tertiary: #025f7d !default;
$dark-theme-text-normal: #fff8eb !default;
$dark-theme-text-dark: #fff1d6 !default;
$dark-theme-text-inverted: #ffffff !default;
$dark-theme-text-primary: #fff8eb !default;
$dark-theme-text-secondary: #fff8eb !default;
$dark-theme-base: #11151c !default;
$dark-theme-error: #942e2e !default;
$theme-primary-light: #394c56 !default;
$theme-primary-normal: #2f3e46 !default;
$theme-primary-dark: #29363d !default;
$theme-secondary-normal: #d2694b !default;
$theme-secondary-dark: #c45131 !default;
$theme-tertiary: #52796f !default;
$theme-text-normal: #29363d !default;
$theme-text-dark: #212b31 !default;
$theme-text-inverted: #ffffff !default;
$theme-text-primary: #ffffff !default;
$theme-text-secondary: #181f23 !default;
$theme-base: #f4f6f3 !default;
$theme-error: #942e2e !default;
$color-theme-light-primary-light: #394c56 !default;
$color-theme-light-primary-normal: #2f3e46 !default;
$color-theme-light-primary-dark: #29363d !default;
$color-theme-light-secondary-normal: #d2694b !default;
$color-theme-light-secondary-dark: #c45131 !default;
$color-theme-light-tertiary: #52796f !default;
$color-theme-light-text-heading: #29363d !default;
$color-theme-light-text-normal: #29363d !default;
$color-theme-light-text-dark: #212b31 !default;
$color-theme-light-text-inverted: #ffffff !default;
$color-theme-light-text-primary: #ffffff !default;
$color-theme-light-text-secondary: #181f23 !default;
$color-theme-light-base: #f4f6f3 !default;
$color-theme-light-error: #942e2e !default;
$color-theme-dark-primary-light: #eabf48 !default;
$color-theme-dark-primary-normal: #e7b836 !default;
$color-theme-dark-primary-dark: #e5b224 !default;
$color-theme-dark-secondary-normal: #1c2636 !default;
$color-theme-dark-secondary-dark: #171c26 !default;
$color-theme-dark-tertiary: #025f7d !default;
$color-theme-dark-text-heading: #fff8eb !default;
$color-theme-dark-text-normal: #fff8eb !default;
$color-theme-dark-text-dark: #fff1d6 !default;
$color-theme-dark-text-inverted: #ffffff !default;
$color-theme-dark-text-primary: #fff8eb !default;
$color-theme-dark-text-secondary: #fff8eb !default;
$color-theme-dark-base: #11151c !default;
$color-theme-dark-error: #942e2e !default;
$color-theme-coral-light-primary-light: #f57a7a !default;
$color-theme-coral-light-primary-normal: #f25757 !default;
$color-theme-coral-light-primary-dark: #f14141 !default;
$color-theme-coral-light-secondary-normal: #234257 !default;
$color-theme-coral-light-secondary-dark: #1d3749 !default;
$color-theme-coral-light-tertiary: #1d3749 !default;
$color-theme-coral-light-text-heading: #1d3749 !default;
$color-theme-coral-light-text-normal: #0c161d !default;
$color-theme-coral-light-text-dark: #060b0e !default;
$color-theme-coral-light-text-inverted: #ffffff !default;
$color-theme-coral-light-text-primary: #0c161d !default;
$color-theme-coral-light-text-secondary: #f5f5f5 !default;
$color-theme-coral-light-base: #f5f5f5 !default;
$color-theme-coral-light-error: #942e2e !default;
$color-theme-coral-dark-primary-light: #f57a7a !default;
$color-theme-coral-dark-primary-normal: #f25757 !default;
$color-theme-coral-dark-primary-dark: #f14141 !default;
$color-theme-coral-dark-secondary-normal: #172c3a !default;
$color-theme-coral-dark-secondary-dark: #11212c !default;
$color-theme-coral-dark-tertiary: #1d3749 !default;
$color-theme-coral-dark-text-heading: #f25757 !default;
$color-theme-coral-dark-text-normal: #f5f5f5 !default;
$color-theme-coral-dark-text-dark: #ebebeb !default;
$color-theme-coral-dark-text-inverted: #f5f5f5 !default;
$color-theme-coral-dark-text-primary: #f5f5f5 !default;
$color-theme-coral-dark-text-secondary: #f5f5f5 !default;
$color-theme-coral-dark-base: #1d3749 !default;
$color-theme-coral-dark-error: #942e2e !default;
$color-theme-forrest-primary-light: #b2be9d !default;
$color-theme-forrest-primary-normal: #a3b18a !default;
$color-theme-forrest-primary-dark: #588157 !default;
$color-theme-forrest-secondary-normal: #344e41 !default;
$color-theme-forrest-secondary-dark: #31493d !default;
$color-theme-forrest-tertiary: #3a5a40 !default;
$color-theme-forrest-text-heading: #a3b18a !default;
$color-theme-forrest-text-normal: #dad7cd !default;
$color-theme-forrest-text-dark: #d4d0c4 !default;
$color-theme-forrest-text-inverted: #344e41 !default;
$color-theme-forrest-text-primary: #dad7cd !default;
$color-theme-forrest-text-secondary: #dad7cd !default;
$color-theme-forrest-base: #293d33 !default;
$color-theme-forrest-error: #942e2e !default;
$color-theme-grayscale-primary-light: #6c757d !default;
$color-theme-grayscale-primary-normal: #343a40 !default;
$color-theme-grayscale-primary-dark: #343a40 !default;
$color-theme-grayscale-secondary-normal: #dee2e6 !default;
$color-theme-grayscale-secondary-dark: #ced4da !default;
$color-theme-grayscale-tertiary: #212529 !default;
$color-theme-grayscale-text-heading: #212529 !default;
$color-theme-grayscale-text-normal: #212529 !default;
$color-theme-grayscale-text-dark: #1b1f22 !default;
$color-theme-grayscale-text-inverted: #f8f9fa !default;
$color-theme-grayscale-text-primary: #212529 !default;
$color-theme-grayscale-text-secondary: #212529 !default;
$color-theme-grayscale-base: #f8f9fa !default;
$color-theme-grayscale-error: #942e2e !default;
$color-theme-pixely-2020-primary-light: #535664 !default;
$color-theme-pixely-2020-primary-normal: #2d3142 !default;
$color-theme-pixely-2020-primary-dark: #252937 !default;
$color-theme-pixely-2020-secondary-normal: #9abd97 !default;
$color-theme-pixely-2020-secondary-dark: #7f9b7c !default;
$color-theme-pixely-2020-tertiary: #025f7d !default;
$color-theme-pixely-2020-text-heading: #2f3e46 !default;
$color-theme-pixely-2020-text-normal: #3c484e !default;
$color-theme-pixely-2020-text-dark: #000000 !default;
$color-theme-pixely-2020-text-inverted: #ffffff !default;
$color-theme-pixely-2020-text-primary: #3c484e !default;
$color-theme-pixely-2020-text-secondary: #3c484e !default;
$color-theme-pixely-2020-base: #f5f1de !default;
$color-theme-pixely-2020-error: #942e2e !default;
$font-family-heading: "Rubik", sans-serif !default;
$font-family-body-copy: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !default;
$font-family-monospace: "Anonymous Pro", monospace !default;
$font-weight-light: 100 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-heavy: 800 !default;
$grid-mobile-template: 10px 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr 10px !default;
$grid-mobile-gap: 10px !default;
$grid-desktop-template: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr !default;
$grid-desktop-gap: 20px !default;
$grid-breakppont-tablet: 400px !default;
$size-spacing-small: 10px !default;
$size-spacing-medium: 20px !default;

$tokens: (
  'dark-theme': (
    'primary': (
      'light': $dark-theme-primary-light,
      'normal': $dark-theme-primary-normal,
      'dark': $dark-theme-primary-dark
    ),
    'secondary': (
      'normal': $dark-theme-secondary-normal,
      'dark': $dark-theme-secondary-dark
    ),
    'tertiary': $dark-theme-tertiary,
    'text': (
      'normal': $dark-theme-text-normal,
      'dark': $dark-theme-text-dark,
      'inverted': $dark-theme-text-inverted,
      'primary': $dark-theme-text-primary,
      'secondary': $dark-theme-text-secondary
    ),
    'base': $dark-theme-base,
    'error': $dark-theme-error
  ),
  'theme': (
    'primary': (
      'light': $theme-primary-light,
      'normal': $theme-primary-normal,
      'dark': $theme-primary-dark
    ),
    'secondary': (
      'normal': $theme-secondary-normal,
      'dark': $theme-secondary-dark
    ),
    'tertiary': $theme-tertiary,
    'text': (
      'normal': $theme-text-normal,
      'dark': $theme-text-dark,
      'inverted': $theme-text-inverted,
      'primary': $theme-text-primary,
      'secondary': $theme-text-secondary
    ),
    'base': $theme-base,
    'error': $theme-error
  ),
  'color': (
    'theme': (
      'light': (
        'primary': (
          'light': $color-theme-light-primary-light,
          'normal': $color-theme-light-primary-normal,
          'dark': $color-theme-light-primary-dark
        ),
        'secondary': (
          'normal': $color-theme-light-secondary-normal,
          'dark': $color-theme-light-secondary-dark
        ),
        'tertiary': $color-theme-light-tertiary,
        'text': (
          'heading': $color-theme-light-text-heading,
          'normal': $color-theme-light-text-normal,
          'dark': $color-theme-light-text-dark,
          'inverted': $color-theme-light-text-inverted,
          'primary': $color-theme-light-text-primary,
          'secondary': $color-theme-light-text-secondary
        ),
        'base': $color-theme-light-base,
        'error': $color-theme-light-error
      ),
      'dark': (
        'primary': (
          'light': $color-theme-dark-primary-light,
          'normal': $color-theme-dark-primary-normal,
          'dark': $color-theme-dark-primary-dark
        ),
        'secondary': (
          'normal': $color-theme-dark-secondary-normal,
          'dark': $color-theme-dark-secondary-dark
        ),
        'tertiary': $color-theme-dark-tertiary,
        'text': (
          'heading': $color-theme-dark-text-heading,
          'normal': $color-theme-dark-text-normal,
          'dark': $color-theme-dark-text-dark,
          'inverted': $color-theme-dark-text-inverted,
          'primary': $color-theme-dark-text-primary,
          'secondary': $color-theme-dark-text-secondary
        ),
        'base': $color-theme-dark-base,
        'error': $color-theme-dark-error
      ),
      'coral-light': (
        'primary': (
          'light': $color-theme-coral-light-primary-light,
          'normal': $color-theme-coral-light-primary-normal,
          'dark': $color-theme-coral-light-primary-dark
        ),
        'secondary': (
          'normal': $color-theme-coral-light-secondary-normal,
          'dark': $color-theme-coral-light-secondary-dark
        ),
        'tertiary': $color-theme-coral-light-tertiary,
        'text': (
          'heading': $color-theme-coral-light-text-heading,
          'normal': $color-theme-coral-light-text-normal,
          'dark': $color-theme-coral-light-text-dark,
          'inverted': $color-theme-coral-light-text-inverted,
          'primary': $color-theme-coral-light-text-primary,
          'secondary': $color-theme-coral-light-text-secondary
        ),
        'base': $color-theme-coral-light-base,
        'error': $color-theme-coral-light-error
      ),
      'coral-dark': (
        'primary': (
          'light': $color-theme-coral-dark-primary-light,
          'normal': $color-theme-coral-dark-primary-normal,
          'dark': $color-theme-coral-dark-primary-dark
        ),
        'secondary': (
          'normal': $color-theme-coral-dark-secondary-normal,
          'dark': $color-theme-coral-dark-secondary-dark
        ),
        'tertiary': $color-theme-coral-dark-tertiary,
        'text': (
          'heading': $color-theme-coral-dark-text-heading,
          'normal': $color-theme-coral-dark-text-normal,
          'dark': $color-theme-coral-dark-text-dark,
          'inverted': $color-theme-coral-dark-text-inverted,
          'primary': $color-theme-coral-dark-text-primary,
          'secondary': $color-theme-coral-dark-text-secondary
        ),
        'base': $color-theme-coral-dark-base,
        'error': $color-theme-coral-dark-error
      ),
      'forrest': (
        'primary': (
          'light': $color-theme-forrest-primary-light,
          'normal': $color-theme-forrest-primary-normal,
          'dark': $color-theme-forrest-primary-dark
        ),
        'secondary': (
          'normal': $color-theme-forrest-secondary-normal,
          'dark': $color-theme-forrest-secondary-dark
        ),
        'tertiary': $color-theme-forrest-tertiary,
        'text': (
          'heading': $color-theme-forrest-text-heading,
          'normal': $color-theme-forrest-text-normal,
          'dark': $color-theme-forrest-text-dark,
          'inverted': $color-theme-forrest-text-inverted,
          'primary': $color-theme-forrest-text-primary,
          'secondary': $color-theme-forrest-text-secondary
        ),
        'base': $color-theme-forrest-base,
        'error': $color-theme-forrest-error
      ),
      'grayscale': (
        'primary': (
          'light': $color-theme-grayscale-primary-light,
          'normal': $color-theme-grayscale-primary-normal,
          'dark': $color-theme-grayscale-primary-dark
        ),
        'secondary': (
          'normal': $color-theme-grayscale-secondary-normal,
          'dark': $color-theme-grayscale-secondary-dark
        ),
        'tertiary': $color-theme-grayscale-tertiary,
        'text': (
          'heading': $color-theme-grayscale-text-heading,
          'normal': $color-theme-grayscale-text-normal,
          'dark': $color-theme-grayscale-text-dark,
          'inverted': $color-theme-grayscale-text-inverted,
          'primary': $color-theme-grayscale-text-primary,
          'secondary': $color-theme-grayscale-text-secondary
        ),
        'base': $color-theme-grayscale-base,
        'error': $color-theme-grayscale-error
      ),
      'pixely-2020': (
        'primary': (
          'light': $color-theme-pixely-2020-primary-light,
          'normal': $color-theme-pixely-2020-primary-normal,
          'dark': $color-theme-pixely-2020-primary-dark
        ),
        'secondary': (
          'normal': $color-theme-pixely-2020-secondary-normal,
          'dark': $color-theme-pixely-2020-secondary-dark
        ),
        'tertiary': $color-theme-pixely-2020-tertiary,
        'text': (
          'heading': $color-theme-pixely-2020-text-heading,
          'normal': $color-theme-pixely-2020-text-normal,
          'dark': $color-theme-pixely-2020-text-dark,
          'inverted': $color-theme-pixely-2020-text-inverted,
          'primary': $color-theme-pixely-2020-text-primary,
          'secondary': $color-theme-pixely-2020-text-secondary
        ),
        'base': $color-theme-pixely-2020-base,
        'error': $color-theme-pixely-2020-error
      )
    )
  ),
  'font': (
    'family': (
      'heading': $font-family-heading,
      'body-copy': $font-family-body-copy,
      'monospace': $font-family-monospace
    ),
    'weight': (
      'light': $font-weight-light,
      'normal': $font-weight-normal,
      'bold': $font-weight-bold,
      'heavy': $font-weight-heavy
    )
  ),
  'grid': (
    'mobile': (
      'template': $grid-mobile-template,
      'gap': $grid-mobile-gap
    ),
    'desktop': (
      'template': $grid-desktop-template,
      'gap': $grid-desktop-gap
    ),
    'breakppont': (
      'tablet': $grid-breakppont-tablet
    )
  ),
  'size': (
    'spacing': (
      'small': $size-spacing-small,
      'medium': $size-spacing-medium
    )
  )
);
