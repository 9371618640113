.footer {
  background: var(--theme-secondary-normal);
  display: grid;
  grid-template-columns: 1fr;
  padding: $grid-mobile-gap 0;
  grid-gap: $grid-mobile-gap;
  height: 100%;

  &__bio,
  &__nav {
    grid-column: 1 / -1;
  }

  &__nav {
    grid-row: 1
  }
  
  &__nav-item {
    color: var(--theme-text-secondary);
    display: block;
    font-weight: $font-weight-bold;
    padding-left: $grid-desktop-gap;
    padding-top: $grid-mobile-gap;
    padding-bottom: $grid-mobile-gap;

    &:hover {
      color: var(--theme-text-dark);
    }
  }

}

@media (min-width: 576px) {
  .footer {
    display: grid;
    grid-template-columns: $grid-desktop-template;
    padding: $grid-desktop-gap 0 0 0;
    grid-gap: $grid-desktop-gap;
  
    &__bio {
      grid-column: 5 / span 4;
    }
  
    &__nav {
      grid-column: 2 / span 3;
      grid-row: 1
    }

    &__nav-item {
      display: inline-block;
      padding: 0;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }      
}