.link {
  color: currentcolor;
  font-weight: var(  --font-bold);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: rgba(0 0 0 / .3);
  transition: all linear .2s;
  box-shadow: 0 0 0 2px transparent;

  &:hover,
  &:focus,
  &:active {
    background: rgba(0 0 0 / .1);
    box-shadow: 0 0 0 2px rgba(0 0 0 / .1);
    text-decoration: none;
    text-decoration-color: rgba(0 0 0 / .1);
  }

  &--inverted {
    color: var(--theme-text-inverted);
    text-decoration-color: rgba(255 255 255 / .3);

    &:hover,
    &:focus,
    &:active {
      background: rgba(255 255 255 / .1);
      box-shadow: 0 0 0 2px rgba(255 255 255 / .1);
      text-decoration-color: rgba(255 255 255 / .1);
    }
  }
}

@media (min-width: 576px) {
  .link {
    text-decoration-thickness: 2px;
  }
}