$type--base: 20px;
$type--extra-small: .694rem;

html { font-size: 18px } /* 20px */

body {
  font-family: $font-family-body-copy;
  font-weight: 400;
  line-height: 1.65;
  color: var(--theme-text-normal);
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  margin: 0;
  font-family: $font-family-heading;
  font-weight: bold;
  line-height: 1.15;
  color: var(--theme-text-heading);
}

h1,
.heading-1 {
  margin-top: 0;
  font-size: 2.488rem;
}

h2,
.heading-2 {
  font-size: 2.074rem;
}

h3,
.heading-3 {
  font-size: 1.728rem;
}

h4,
.heading-4 {
  font-size: 1.44rem;
}

h5,
.heading-5 {
  font-size: 1.2rem;
}

h6,
.heading-6 {
  font-size: 1rem;
}

p {
  margin: 0;
}

small,
.text-s {
  font-size: .833rem;
}

.text-xs {
  font-size: $type--extra-small;
}

.text-xxs {
  font-size: .579rem;
}

.text--inverted {
  color: var(--theme-text-inverted);
}

@media (min-width: 576px) {
  html { font-size: 20px } /* 20px */

}
